import gql from "graphql-tag";

export const CrearStockScanned = gql`
mutation CrearStockScanned($codigo:String!,$stock:String!)
{
  CrearStockScanned(codigo: $codigo ,
                    stock: $stock)
  {
    success
		message
  }    
}`;

