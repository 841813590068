import React,{useEffect,useState} from 'react';
import Inventario from './Views/Invenario';
import Buscar from './Component/Loading';
import '@ant-design/cssinjs';
import getApolloClient from './getApolloClient'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd';

import esES from 'antd/locale/es_ES';


function App() {
  const [client, setClient] = useState(null)
  const [loading, setLoading] = useState(true)
  const [locale] = useState(esES);

  useEffect(() => {
    getApolloClient().then((client) => {
      setClient(client)
      setLoading(false)
    })
  }, [])

  if(loading){
      return (
      <div className="site-card-border-less-wrapper">
          
        <Buscar/>
        
      </div>
      )
  }

  return (
   
    <ApolloProvider client={client}>
     <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1677FF',
          },
        }} 
        locale={locale}
      >
        <Inventario />
      </ConfigProvider>    
    </ApolloProvider>
  
    
  );
}

export default App;
