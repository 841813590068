import React,{useState,useEffect} from 'react'
import { useZxing } from "react-zxing";

import { Modal,Layout,Card,Button,Row,Col,} from 'antd';
import Buscar from '../Component/Loading';
import ModalStock from '../Component/Inventario/ModalStock';
import ModalMenu from '../Component/Inventario/ModalMenu';

import {useCrearStockScanned,useGetQrProduct} from '../hooks/custom-ProductosQr';


const { Header } = Layout;
const Inventario = () => {
   
    const {data,loading} = useGetQrProduct();
    const [SetCreate] = useCrearStockScanned();
    const [result, setResult] = useState("");


    const [inventariodata, setInventariodata] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [OpenMant, setOpenMant] = useState(false);
    const [OpenMenu, setOpenMenu] = useState(false);

   
   

    useEffect(() => {
        if(loading===false){
            const dataInventariodata = data.GetQrProduct
            setInventariodata(dataInventariodata)
        }    
    }, [data, loading])
    
     const handleSave = async (values) => {
        let registros = 1
        
        const find  = inventariodata.find(item => item.codigo === values);
        if(!find){ 
            if(inventariodata.length>0) {
            registros = parseInt(inventariodata[0].id_qr_product_scanned,10) + 1; 
            }
            let array={
                    id_qr_product_scanned:registros,
                    codigo:values,
                    Descripcion:'Pendiente',
                    Stock:0,
                    Stock_real:0,
                    Fecha_creacion:'01/01/2022',
                    estado:'Pendiente',
                    color:'gold',
                    __typename: "returnTAvisoType"
                }
                
            setInventariodata([array,...inventariodata])
        
            const mensajeop='Enviado correctamente'
            const  modal = Modal.success({
                        content: mensajeop,
                    });

                    setTimeout(() => {
                        setLoading(false);
                        modal.destroy();
                    }, 3000);



            await  SetCreate({variables:{
                    codigo : values,
                    stock :"0",
                }
            })
        }
       
        
       
       
    };


    const { ref } = useZxing({
        onResult(result) {
        handleSave(result.getText())    
        setResult(result.getText());
        },
    });

    const showDrawer = () => {
        setOpenMant(true);
    };

    const onClose = () => {
        setOpenMant(false);
    };

    const onCloseMenu = () => {
        setOpenMenu(false);
    };

     const onOpenMenu = () => {
        setOpenMenu(true);
    };

     const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
    
    if(loading){
        return (
          <Buscar/> 
        )
    }


    return (
        
         <div >
       
            <Header  style={{backgroundColor: "white",padding: '15px 56px', 'borderBottom':'1px solid rgba(140, 140, 140, 0.35)'}}>
                <Row gutter={16}>
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 16}}>
                    <Button key ={"b1"} onClick={onOpenMenu}  icon={<Icon type='BarsOutlined' />}/>
                </Col>
                <Col xs={{ span: 3, offset: 0 }} lg={{ span: 6 }}>
                    <Button onClick={showDrawer}  type="primary">Lista Scan</Button>
                </Col>
                </Row>    
            </Header>   
            <Card size="small" style={{ textAlign: 'center', border:'none' }}   >
                
                <video ref={ref}  width='380vh' height={'380vh'}/>
                <p>
                    <span>Last result:</span>
                    <span>{result}</span>
                </p>
                <ModalStock OpenMant={OpenMant} Loading={Loading} handleSave={handleSave} handleCancelMant={onClose} inventariodata = {inventariodata}/>
                <ModalMenu OpenMenu={OpenMenu} Loading={Loading}  handleCancelMenu={onCloseMenu}  />
                
            </Card>
        </div>
       
    );
}

export default Inventario