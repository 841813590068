import React from 'react';

import { Button, Space,Drawer,} from 'antd';
import ListaInvetario from '../Inventario/ListaInventario';




const ModalStock = ({ OpenMant, Loading, handleSave, handleCancelMant,inventariodata}) => {
  
    const onClose = () => {
       handleCancelMant(false);
    };
    
    
    return (
         <Drawer
                    title="Scan Barras"
                    width={window.innerWidth > 900 ? '70%' : '100%'}
                   
                    onClose={onClose}
                    open={OpenMant}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={
                    <Space>
                        <Button key="1" onClick={onClose}  >Cerrar</Button>
                        
                    </Space>
                    }
                >
       
            <ListaInvetario  inventariodata={inventariodata} />
        
        
           
           
        </Drawer>
    )
}

export default ModalStock
