
import {useQuery,useMutation} from '@apollo/client'
import {GetQrProduct} from "../Models/query/ProductosQr/ProductQr"
import {CrearStockScanned} from "../Models/mutation/ProductosQr/ProductosQr"


export const useGetQrProduct = () =>{
    const result = useQuery(GetQrProduct, {    
    fetchPolicy : 'network-only' , // Usado para la primera ejecución  
    nextFetchPolicy : 'cache-first' , // Usado para ejecuciones posteriores  
    });
    return result;
}

export const useCrearStockScanned = () =>{
   const  result = useMutation(CrearStockScanned,{refetchQueries:[{query:GetQrProduct}]});                  
   return result;
}


export default useGetQrProduct


