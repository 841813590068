import gql from "graphql-tag";

export const GetQrProduct = gql`
query{
  GetQrProduct{
    id_qr_product_scanned
    codigo
    Descripcion
    Stock
    Stock_real
    Fecha_creacion
    estado
    color
  }
}`;



